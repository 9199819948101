<template>
	<Layout>
		<PageHeader :title="title" />
		<div class="mb-4 text-center">
			<b-alert
				show
				role="alert"
				variant="info"
			>
				<strong>Tip:</strong>
				Select a date range, project, and tag, then click "Generate Report". All fields are optional.
			</b-alert>
		</div>
		<ReportFilters 
			@generateReport="generateReport"
			:isLoading="isLoading"
		/>
		<div 
			v-if="!isLoading && isLoading !== null && timeEntriesTableData.length"
			class="card"
		>
			<div class="card-body">
				<div class="row">
					<div 
						v-if="projectsData && !isLoading"
						class="col-md-5"
					>
						<div class="card">
							<div class="card-body">
								<h4 class="card-title mb-4">
									Project Totals
								</h4>
									<TopProjectsPieChart
										v-if="projectsData && projectsData.length"
										:chartData="projectsData"
										:height="207"
									/>
							</div>
						</div>
					</div>
					<div 
						v-if="tagsData && !isLoading"
						class="col-md-5"
					>
						<div class="card">
							<div class="card-body">
								<h4 class="card-title mb-4">
									Top 10 Tags
								</h4>
								<TagsPieChart
									:height="207"
									:tagsData="tagsData"
								/>
							</div>
						</div>
					</div>
					<div class="col-md-2">
						<SummaryStatistics
							v-if="statsData && !isLoading"
							:statsData="statsData"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-12">
				<SimpleTimeEntriesTable
					v-if="timeEntriesTableData && !isLoading && timeEntriesTableData.length"
					:tableData="timeEntriesTableData"
				/>
			</div>
		</div>

		<div
			v-if="!isLoading && isLoading !== null && !timeEntriesTableData.length"
			class="text-center mt-5"
		>
			<i class="mdi mdi-file-find mdi-48px"></i>
			<h2 class="text-center empty-table">Can't find anything!</h2>
			<p class="text-center text-muted">You don't have any time entries that match this criteria.</p>
		</div>
	</Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import ReportFilters from '@/components/client-dashboard/report-filters';
import SimpleTimeEntriesTable from '@/components/client-dashboard/simple-time-entries-table';
import StatisticsService from '@/services/statistics';
import SummaryStatistics from '@/components/client-dashboard/summary-statistics';
import TimeEntriesService from '@/services/time-entries';
import TagsPieChart from '@/components/client-dashboard/tags-pie-chart';
import TopProjectsPieChart from '../../../components/client-dashboard/top-projects-pie-chart.vue';

export default {
	page: {
		title: "Reports",
		meta: [{ name: "Reports", content: appConfig.description }]
	},
	components: { 
		Layout,
		PageHeader,
		ReportFilters,
		SimpleTimeEntriesTable,
		SummaryStatistics,
		TagsPieChart,
		TopProjectsPieChart,
	},
	data() {
		return {
			isLoading: null,
			title: "Reports",
			filters: {
				dateFrom: null,
				dateTo: null,
				projectId: null,
				tagId: null,
			},
			statsData: null,
			timeEntriesTableData: null,
			tagsData: null,
			projectsData: null,
		};
	},
	computed: {
		userId() {
			return this.$store.state.user.userInfo.id;
		},
	},
	methods: {
		async generateReport(filters) {
			this.isLoading = true;
			this.filters.dateFrom = filters.dateFrom;
			this.filters.dateTo = filters.dateTo;
			this.filters.projectId = filters.projectId;
			this.filters.tagIds = filters.tagIds;

			this.statsData = await StatisticsService.summary(this.userId, this.filters);
			this.tagsData = await StatisticsService.tagTotals(this.userId, {
				...this.filters,
				limit: 10,
			});
			this.projectsData = await StatisticsService.projectTotals(this.userId, {
				dateFrom: filters.dateFrom,
				dateTo: filters.dateTo,
				projectId: filters.projectId,
				tagIds: filters.tagIds,
			});
			this.timeEntriesTableData = await TimeEntriesService.listUnpaginated(this.userId, {
				...this.filters,
				sort: 'date',
				sortDesc: 1,
			});

			this.isLoading = false;
		}
	},
	mounted() {
		this.$store.dispatch('user/refreshUserInfo');
	}
};
</script>