<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<div class="date-range-quick-selectors">
						<b-button @click="setDateRange('today')" class="date-selector" pill variant="primary">Today</b-button>
						<b-button @click="setDateRange('week')" class="date-selector" pill variant="primary">This Week</b-button>
						<b-button @click="setDateRange('month')" class="date-selector" pill variant="primary">This Month</b-button>
						<b-button @click="setDateRange('year')" class="date-selector" pill variant="primary">This Year</b-button>
						<b-button @click="setDateRange('all')" class="date-selector" pill variant="primary">All Time</b-button>
					</div>
					<form>
						<div class="row">
							<div class="form-group col-lg-2">
								<label>Start Date</label>
								<br />
								<DatePicker
									v-model="filters.dateFrom"
									format="DD/MM/YYYY"
									value-type="YYYY-MM-DD"
									append-to-body
									:lang="lang"
									placeholder="Select date"
								></DatePicker>
							</div>

							<div class="form-group col-lg-2">
								<label>End Date</label>
								<br />
								<DatePicker
									v-model="filters.dateTo"
									format="DD/MM/YYYY"
									value-type="YYYY-MM-DD"
									append-to-body
									:lang="lang"
									placeholder="Select date"
									:disabled-date="isBeforeStartDate"
								></DatePicker>
							</div>

							<div class="form-group col-lg-2">
								<label for="project">Project</label>
								<select class="form-control" v-model="filters.projectId">
									<option value="">
										*** All Projects ***
									</option>
									<option v-for="project in projects" :key="project.id" :value="project.id">
										{{ project.project_name }}
									</option>
								</select>
							</div>

							<div class="form-group col-lg-2">
								<label for="tag">Tag</label>
								<vue-tags-input
									id="tags"
									v-model="tag"
									:tags="filters.tags"
									:autocomplete-items="filteredItems"
									:autocomplete-min-length="0"
									:separators="separators"
									:add-only-from-autocomplete="true"
									@tags-changed="newTags => filters.tags = newTags"
								/>
							</div>

							<div class="col-lg-2 align-self-center mt-2">
								<b-button
									variant="primary"
									class="w-md"
									@click="handleGenerateReport"
									:disabled="isLoading"
									v-shortkey.once="['ctrl', 'enter']"
									@shortkey="handleGenerateReport"
								>
									<i v-if="isLoading" class="fas fa-spin fa-circle-notch"></i>
									<span v-else>Generate Report</span>
								</b-button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import UserProjectsService from '@/services/user-projects';
import UserTagsService from '@/services/user-tags';
import DatePicker from 'vue2-datepicker';
import VueTagsInput from '@johmun/vue-tags-input';

export default {
	props: {
		isLoading: {
			type: Boolean,
		},
	},

	name: 'ReportFilters',

	components: {
		DatePicker,
		VueTagsInput,		
	},

	computed: {
		userInfo() {
			return this.$store.state.user.userInfo;
		},
		filteredItems() {
			return this.autocompleteItems.filter(i => {
				return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
			});
		},
	},

	watch: {
		async userInfo(userInfo) {
			const projects = await UserProjectsService.listAll(this.userInfo.id);
			this.projects = projects;

			const tags = await UserTagsService.listAll(this.userInfo.id);
			this.autocompleteItems = tags;
		},
	},

	data() {
		return {
			filters: {
				dateFrom: null,
				dateTo: null,
				projectId: null,
				tags: [],
			},
			projects: [],
			tag: '',
			autocompleteItems: [],
			separators: [' ', ','],
			lang: {
				formatLocale: {
					firstDayOfWeek: 1,
				},
			},
		}
	},

	methods: {
		isBeforeStartDate(date) {
			const dateFrom = this.filters.dateFrom ? this.$moment(this.filters.dateFrom) : null;
			const dateTo = this.$moment(date);
			if (dateFrom !== null && dateTo.isBefore(dateFrom, 'day')) {
				return true;
			}
		},

		setDateRange(range) {
			switch (range) {
				case 'today':
					this.filters.dateFrom = this.$moment().format('YYYY-MM-DD');
					this.filters.dateTo = this.$moment().format('YYYY-MM-DD');
					break;
				case 'week':
					this.filters.dateFrom = this.$moment().startOf('week').format('YYYY-MM-DD');
					this.filters.dateTo = this.$moment().endOf('week').format('YYYY-MM-DD');
					break;
				case 'month':
					this.filters.dateFrom = this.$moment().startOf('month').format('YYYY-MM-DD');
					this.filters.dateTo = this.$moment().endOf('month').format('YYYY-MM-DD');
					break;
				case 'year':
					this.filters.dateFrom = this.$moment().startOf('year').format('YYYY-MM-DD');
					this.filters.dateTo = this.$moment().endOf('year').format('YYYY-MM-DD');
					break;
				case 'all':
					this.filters.dateFrom = null;
					this.filters.dateTo = null;
					break;
			}
		},

		handleGenerateReport() {
			if (this.isLoading) return;
			const filters = this.filters;
			filters.tagIds = this.filters.tags.map(filter => filter.id).join();
			this.$emit('generateReport', filters);
		},
	},
}
</script>

<style lang="scss" scoped>
.filters-header {
	margin-bottom: 15px;
}

.date-range-quick-selectors {
	margin-bottom: 15px;

	button {
		margin-right: 12px;
	}
}
</style>

<style lang="scss">
.vue-tags-input {
	.ti-tag {
		cursor: default;
		border-radius: 0.25rem;
	}

	.ti-tag-center, .ti-new-tag-input, .ti-item {
		font-family: "Roboto", sans-serif;
	}

	.ti-tag:before {
		content: none;
	}

	.ti-autocomplete ul {
		max-height: 250px;
		overflow-y: scroll;
	}
}
</style>