<template>
	<div class="row">
		<div class="col-md-12">
			<div class="card text-center">
				<div class="card-body">
					<div class="py-4">
						<h5 class="mt-0 mb-4 font-size-16">Time Logged</h5>
						<h1>{{ totalDuration }}</h1>
					</div>
				</div>
			</div>
		</div>

		<div class="col-md-12">
			<div class="card text-center">
				<div class="card-body">
					<div class="py-4">
						<h5 class="mt-0 mb-4 font-size-16">Time Entries</h5>
						<h1>{{ statsData.count }}</h1>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import formatDuration from '@/utils/format-duration';

export default {
	name: 'SummaryStatistics',

	props: {
		statsData: {
			type: Object,
		}
	},

	computed: {
		totalDuration() {
			return formatDuration(this.statsData.duration);
		},
	},
}
</script>