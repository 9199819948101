<script>
import { Pie } from "vue-chartjs";
import formatDuration from '@/utils/format-duration';

export default {
  extends: Pie,
	props: {
		tagsData: {
			type: Array,
		}
	},
	computed: {
		chartLabels() {
			return this.tagsData.map(val => val.text);
		},
		chartNumbers() {
			return this.tagsData.map(val => val.duration);
		},
	},
	mounted() {
		this.makeChart();
	},
	methods: {
		makeChart() {
			this.renderChart(
				{
					labels: this.chartLabels,
					datasets: [
						{
							data: this.chartNumbers,
							backgroundColor: [
								'#4dc9f6',
								'#f67019',
								'#f53794',
								'#537bc4',
								'#acc236',
								'#166a8f',
								'#00a950',
								'#58595b',
								'#8549ba',
								'#ffcb15',
							],
						}
					]
				},
				{
					maintainAspectRatio: true,
					responsive: true,
					tooltips: {
						enabled: true,
						callbacks: {
							label: function({ index }, data) {
								const tagName = data.labels[index];
								const duration = data.datasets[0].data[index];
								const formattedDuration = formatDuration(duration);
								return `${tagName} - ${formattedDuration}`;
							},
						}
					},
				}
			);
		},
	},
};
</script>