<template>
	<div class="card">
		<div class="card-body">
			<h4 class="card-title">
				Time Entries Table
			</h4>
			<b-table
				:items="tableData"
				:fields="fields"
			>
				<template #cell(ProjectName)="data">
					<div v-if="data.item.project">
						{{ data.item.project.project_name }}
					</div>
				</template>
				<template #cell(Tags)="data">
					<div v-if="data.item.tags.length">
						<span
							v-for="tag in data.item.tags"
							:key="tag.id"
						>
							<b-badge
								class="badge"
								variant="primary"
							>
								#{{ tag.text }}
							</b-badge>
						</span>
					</div>
				</template>
				<template #cell(duration)="data">
					<span>{{ formatDuration(data.item.duration) }}</span>
				</template>
			</b-table>
		</div>
	</div>
</template>

<script>
import formatDuration from '@/utils/format-duration';

export default {
	name: 'SimpleTimeEntriesTable',

	props: {
		tableData: {
			type: Array,
		},
	},

	data() {
		return {
			fields: [
				{ key: "duration", sortable: false },
				'ProjectName',
				'Tags',
				{ key: "date", sortable: false },
			],
		}
	},

	methods: {
		formatDuration(minutes) {
			return formatDuration(minutes);
		},
	},
}
</script>

<style lang="scss" scoped>
.badge {
	margin-right: 5px;
	cursor: default;
}
</style>